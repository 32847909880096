import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCountry from "../../hooks/useCountry";
import { withRouter } from "react-router-dom";
import { tz } from "moment-timezone";
import * as FileSaver from "file-saver";
import { Button } from "antd";
import useFilters from "hooks/useFilters";
import useColorTheme from "../../hooks/useColorTheme";
import { BUTTONS_TYPE } from "constants/buttons-type.constants";

import {
  fetchActiveCompanyMembers,
  fetchExportFilters,
  createScheduledReport,
  downloadScheduledReport,
} from "store/exports/actions";

import TemplateReport from "components/TabsExport/components/TemplateReport";
import LoaderBox from "components/LoaderBox";
import { sectionsIds, sectionsSummary, getDays } from "components/TabsExport/exportSections";

import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import MsgPopup from "components/MsgPopup";

const ScheduledReports = ({ match: { path }, onClose }) => {
  const dispatch = useDispatch();
  const [isShowExportMsg, setIsShowExportMsg] = useState(false);
  const { primaryColor } = useColorTheme();

  const { companyMembers, status } = useSelector((state) => state.exports);
  const { mainLastFilter: lastFilter } = useFilters();
  const {
    filters: { filters },
  } = useSelector((state) => state.exports);

  const {
    filters: {
      date: { relative },
    },
  } = useSelector((state) => state.filters);
  const { currentCountry } = useCountry();

  const getFilterArray = (string) => {
    return string
      .split("|")
      .map((item) => Number(item))
      .filter((item) => item);
  };

  const initialValues = {
    name: "",
    options: {
      pricing: {
        promotion: false,
        pricingMechanic: "shelfPrice",
        viewLogic: "daily",
        weeklyType: "average",
        snapshotDay: 1,
        productGroup: false,
      },
      content: {},
      navigation: false,
      promotions: {
        viewLogic: "daily",
        weeklyType: "average",
        snapshotDay: 1,
        grouped: false,
        viewType: "retailer",
        fillColor: "mechanic",
        productGroup: false,
      },
      rating: {
        hasViewByRetailer: false,
        viewByRetailerType: "summary",
        hasViewByProduct: false,
        viewByProductType: "summary",
        hasDetailedView: false,
        isAllReviews: false,
        hasNoReviews: false,
        isSkipNewReviews: false,
      },
      availability: {},
      ranking: {},
      media: false,
      assortment: false,
    },
    filterParams: {
      sourceType: getFilterArray(lastFilter.sourceType),
      category: getFilterArray(lastFilter.category),
      manufacture: getFilterArray(lastFilter.manufacture),
      productBrand: getFilterArray(lastFilter.productBrand),
      productGroup: getFilterArray(lastFilter.productGroup),
      product: getFilterArray(lastFilter.product),
      timePeriod: getDays(
        (
          relative.find((item) => item.id === 4) || {
            id: relative[relative.length - 1].id,
          }
        ).id
      ),
    },
    schedule: {
      type: "daily",
      days: [0],
      timezone: tz.guess(),
    },
    emails: [""],
    users: [],
  };

  const [sectionId, setSectionId] = useState("");

  const [name, setName] = useState(initialValues.name);
  const [options, setOptions] = useState(initialValues.options);
  const [filterParams, setFilterParams] = useState(initialValues.filterParams);
  const [schedule, setSchedule] = useState(initialValues.schedule);

  const [emails, setEmails] = useState(initialValues.emails);
  const [users, setUsers] = useState(initialValues.users);

  const clearState = () => {
    setName(initialValues.name);
    setOptions(initialValues.options);
    setFilterParams(initialValues.filterParams);
    setSchedule(initialValues.schedule);
    setEmails(initialValues.emails);
    setUsers(initialValues.users);
  };

  useEffect(() => {
    setSectionId(sectionsIds[path.split("/")[1]]);
  }, [path]);

  useEffect(() => {
    dispatch(fetchActiveCompanyMembers());

    const filtersData = { ...lastFilter, timePeriod: getTimePeriod(lastFilter.date), save: false };
    dispatch(fetchExportFilters(filtersData));
  }, []);

  const handleSetOptions = (name, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [sectionsSummary[sectionId]]: { ...prevState[sectionsSummary[sectionId]], [name]: value },
    }));
  };

  const setScheduleType = (value) => {
    setSchedule((prevState) => ({
      ...prevState,
      ["type"]: value,
    }));
  };

  const setScheduleDays = (value) => {
    if (schedule.days.includes(value)) {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: prevState["days"].filter((item) => item !== value),
      }));
    } else {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: [...prevState["days"], value],
      }));
    }
  };

  const handleChangeInput = (value, indexVal) => {
    const values = emails.map((item, index) => {
      if (index === indexVal) {
        return (item = value);
      }
      return item;
    });

    setEmails(values);
  };

  const handleAddInput = () => {
    setEmails((prevState) => [...prevState, ""]);
  };

  const handleDeleteInput = (indexDel) => {
    const inputs = emails.filter((item, index) => index !== indexDel);
    setEmails(inputs);
  };

  const handleSetMembers = (id) => {
    if (users.includes(id)) {
      const selected = users.filter((item) => item !== id);
      setUsers(selected);
    } else {
      setUsers((prevState) => [...prevState, id]);
    }
  };

  const queryParams = {
    sourceType: filterParams.sourceType.join("|"),
    productBrand: filterParams.productBrand.join("|"),
    category: filterParams.category.join("|"),
    manufacture: filterParams.manufacture.join("|"),
    productGroup: filterParams.productGroup.join("|"),
    product: filterParams.product.join("|"),
    save: false,
  };

  const setFiltersParams = (name, selectItems) => {
    const sendParams = {
      ...queryParams,
      ...{ [name]: Array.isArray(selectItems) ? selectItems.join("|") : selectItems },
    };

    dispatch(fetchExportFilters(sendParams)).then((resolve) => {
      const filterNames = ["sourceType", "category", "manufacture", "productBrand", "productGroup", "product"];
      const { filters } = resolve.data;

      Object.keys(filters).forEach((filterName) => {
        if (filterNames.includes(filterName)) {
          const currentFilters = Array.isArray(filters[filterName])
            ? filters[filterName].map((item) => item.id)
            : Object.values(filters[filterName])
                .flat()
                .map((item) => item.id);

          const selectItems = getFilterArray(sendParams[filterName]).filter((item) => currentFilters.includes(item));

          setFilterParams((prevState) => ({
            ...prevState,
            [filterName]: selectItems,
          }));
        }
      });
    });
  };

  const setFiltersParamsDate = (timePeriod) => {
    let isSkipNewReviews = false;
    if (isNaN(timePeriod)) {
      const timeSelected = timePeriod.split("|");
      if (timeSelected.length > 3 && timeSelected[3] === "ful") {
        handleSetOptions("isAllReviews", true);
        isSkipNewReviews = true;
      }
    }
    handleSetOptions("isSkipNewReviews", isSkipNewReviews);

    setFilterParams((prevState) => ({
      ...prevState,
      timePeriod,
    }));
  };

  const handleCreate = () => {
    dispatch(
      createScheduledReport({
        name,
        schedule,
        sectionId,
        options: options[sectionsSummary[sectionId]],
        filters: { ...queryParams, ...{ timePeriod: filterParams.timePeriod }, countryId: currentCountry.countryId },
        share: {
          users,
          emails: emails[0].length ? emails : [],
        },
      })
    ).then(() => clearState());
  };

  const handleCreateRun = () => {
    dispatch(
      createScheduledReport({
        name,
        schedule,
        sectionId,
        options: options[sectionsSummary[sectionId]],
        filters: { ...queryParams, ...{ timePeriod: filterParams.timePeriod }, countryId: currentCountry.countryId },
        share: {
          users,
          emails: emails[0].length ? emails : [],
        },
      })
    ).then((response) =>
      dispatch(downloadScheduledReport(response.data.id)).then((response) => {
        if (response && response.data && response.data.status !== STATE_STATUSES.READY) {
          setIsShowExportMsg(true);
        } else if (response && response.data && response.data.file) {
          let blob = new Blob([Buffer.from(response.data.file, "base64")], {
            type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          FileSaver.saveAs(blob, `${name}.xlsx`);
        }
        clearState();
      })
    );
  };
  const closeModal = () => {
    setIsShowExportMsg(false);
    onClose();
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="export-tab">
        <TemplateReport
          setFiltersParamsDate={setFiltersParamsDate}
          setFiltersParams={setFiltersParams}
          handleSetMembers={handleSetMembers}
          handleDeleteInput={handleDeleteInput}
          handleAddInput={handleAddInput}
          handleChangeInput={handleChangeInput}
          setScheduleDays={setScheduleDays}
          setScheduleType={setScheduleType}
          handleSetOptions={handleSetOptions}
          name={name}
          setName={setName}
          filterParams={filterParams}
          filters={filters}
          setSectionId={setSectionId}
          sectionId={sectionId}
          options={options}
          companyMembers={companyMembers}
          users={users}
          schedule={schedule}
          emails={emails}
          disabled={false}
        />
        {options[sectionsSummary[sectionId]] && (
          <div style={{ display: "flex" }}>
            <Button
              style={{ marginTop: "30px", marginRight: "15px" }}
              className="export-button"
              disabled={
                !Boolean(name) ||
                !Boolean(name.trim()) ||
                Boolean(
                  sectionId === 6 &&
                    !options.rating.hasViewByRetailer &&
                    !options.rating.hasViewByProduct &&
                    !options.rating.hasDetailedView
                )
              }
              onClick={handleCreate}
            >
              Create Report
            </Button>
            <Button
              style={{ marginTop: "30px" }}
              className="export-button"
              disabled={
                !Boolean(name) ||
                !Boolean(name.trim()) ||
                Boolean(
                  sectionId === 6 &&
                    !options.rating.hasViewByRetailer &&
                    !options.rating.hasViewByProduct &&
                    !options.rating.hasDetailedView
                )
              }
              onClick={handleCreateRun}
            >
              Create Report & Run Now
            </Button>
          </div>
        )}
      </div>
      {status === STATE_STATUSES.PENDING && <LoaderBox />}
      <MsgPopup
        themeColor={primaryColor}
        buttonType={BUTTONS_TYPE.SOLID}
        onOk={closeModal}
        onCancel={closeModal}
        isShow={isShowExportMsg}
        title={"Report record created and your report is being prepared"}
        message={"You will be notified when the report is ready to download"}
      />
    </div>
  );
};

export default withRouter(ScheduledReports);
