const url = process.env.REACT_APP_URL;
const urlReport = process.env.REACT_APP_REPORT_URL;

export const DOWNLOAD_QUICK_REPORT = "DOWNLOAD_QUICK_REPORT";
export const downloadQuickReport = (data) => ({
  type: DOWNLOAD_QUICK_REPORT,
  request: {
    url: `${url}/v2/export/download`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const DOWNLOAD_SCHEDULED_REPORT = "DOWNLOAD_SCHEDULED_REPORT";
export const downloadScheduledReport = (id) => ({
  type: DOWNLOAD_SCHEDULED_REPORT,
  request: {
    url: `${url}/v2/export/download/${id}`,
    method: "GET",
  },
  meta: {
    asPromise: true,
  },
});
export const DOWNLOAD_REPORT_BY_KEY = "DOWNLOAD_REPORT_BY_KEY";
export const downloadReportByKey = (key) => ({
  type: DOWNLOAD_REPORT_BY_KEY,
  request: {
    url: `${url}/v2/export/download/key`,
    method: "POST",
    data: { key },
  },
  meta: {
    asPromise: true,
  },
});
export const ACK_REPORTS = "ACK_REPORTS";
export const ackReports = ({keys}) => ({
  type: ACK_REPORTS,
  request: {
    url: `${url}/v2/export/download/ack`,
    method: "POST",
    data: { keys },
  },
  meta: {
    asPromise: true,
  },
});
export const CREATE_SCHEDULED_REPORT = "CREATE_SCHEDULED_REPORT";
export const createScheduledReport = (data) => ({
  type: CREATE_SCHEDULED_REPORT,
  request: {
    url: `${url}/v2/export`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_ACTIVE_COMPANY_MEMBERS = "FETCH_ACTIVE_COMPANY_MEMBERS";
export const fetchActiveCompanyMembers = () => ({
  type: FETCH_ACTIVE_COMPANY_MEMBERS,
  request: {
    url: `${url}/auth/company/members`,
    method: "GET",
  },
});

export const FETCH_EXPORT_FILTERS = "FETCH_EXPORT_FILTERS";
export const fetchExportFilters = (data) => ({
  type: FETCH_EXPORT_FILTERS,
  request: {
    url: `${url}/v2/filters`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_SAVED_REPORTS = "FETCH_SAVED_REPORTS";
export const fetchSavedReports = () => ({
  type: FETCH_SAVED_REPORTS,
  request: {
    url: `${url}/v2/export`,
    method: "GET",
  },
});
export const FETCH_DOWNLOAD_REPORTS_QUEUE = "FETCH_DOWNLOAD_REPORTS_QUEUE";
export const fetchDownloadReportsQueue = () => ({
  type: FETCH_DOWNLOAD_REPORTS_QUEUE,
  request: {
    url: `${url}/v2/export/download/queue/status`,
    method: "GET",
  },
});
export const CLEAR_DOWNLOADED_FILES = "CLEAR_DOWNLOADED_FILES";
export const clearDownloadedFiles = () => {
  return {
    type: CLEAR_DOWNLOADED_FILES,
  };
};
export const FETCH_SAVED_REPORT = "FETCH_SAVED_REPORT";
export const fetchSavedReport = (id) => ({
  type: FETCH_SAVED_REPORT,
  request: {
    url: `${url}/v2/export/${id}`,
    method: "GET",
  },
  meta: {
    asPromise: true,
  },
});

export const DELETE_SAVED_REPORT = "DELETE_SAVED_REPORT";
export const deleteSavedReport = (id) => ({
  type: DELETE_SAVED_REPORT,
  request: {
    url: `${url}/v2/export/${id}`,
    method: "DELETE",
  },
});

export const EDIT_SAVED_REPORT = "EDIT_SAVED_REPORT";
export const editSavedReport = (id, data) => ({
  type: EDIT_SAVED_REPORT,
  request: {
    url: `${url}/v2/export/${id}`,
    method: "PUT",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const CLEAR_SAVED_REPORT = "CLEAR_SAVED_REPORT";
export const clearSavedReport = () => {
  return {
    type: CLEAR_SAVED_REPORT,
  };
};
